/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2023-07-07 18:06:06
 */

import { service } from './service.js'
const api = {
	getAPK(req) {
		return service.get(process.env.VUE_APP_GETAPP_API + '/promote', req)
	},
}


function download(apk,name) {
	// 创建隐藏的可下载链接
	var eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)
	eleLink.style.display = 'none'
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	var reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
	var r = window.location.search.substr(1).match(reg)
	if (r != null) return unescape(r[2])
	return null
}

function formatNum(num) {
	if (num) {
		return num.toString().replace(/\B(?=(?:\d{3})+\b)/g, ',')
	} else {
		return 0
	}
}

function debounce(fn, wait) {
	console.log('ababa', fn)

	//防抖
	var timeout = null
	return function() {
		if (timeout !== null) clearTimeout(timeout)
		timeout = setTimeout(fn, wait)
	}
}
function throttle(func, delay) {
	//节流
	var timer = null
	var startTime = Date.now() //设置开始时间
	return function() {
		var curTime = Date.now()
		var remaining = delay - (curTime - startTime) //剩余时间
		var context = this
		var args = arguments
		clearTimeout(timer)
		if (remaining <= 0) {
			// 第一次触发立即执行
			func.apply(context, args)
			startTime = Date.now()
		} else {
			timer = setTimeout(func, remaining) //取消当前计数器并计算新的remaining
		}
	}
}

function formatDate(oldDate, fmt) {
	//oldDate 13位
	//fmt為格式化 exp：yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}
	function padLeftZero(str) {
		return ('00' + str).substr(str.length)
	}
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	}
	return fmt
}


function isJSON(str) {
	if (typeof str == 'string') {
		try {
			var obj = JSON.parse(str)
			if (typeof obj == 'object' && obj) {
				return true
			} else {
				return false
			}
		} catch (e) {
			console.log('error：' + str + '!!!' + e)
			return false
		}
	}
	console.log('It is not a string!')
}


// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatNum,
	formatDate,
	isJSON,
	download,
	api,
	debounce,
	throttle,
}

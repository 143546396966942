import Vue from 'vue'
import App from './App.vue'
import global from '@/utlis/global.js'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
const firebaseConfig = {
	apiKey: "AIzaSyCfsHcnvodGTdtHt2cin_X9QGWG4qg11KY",
  authDomain: "all-player-bf5d9.firebaseapp.com",
  projectId: "all-player-bf5d9",
  storageBucket: "all-player-bf5d9.appspot.com",
  messagingSenderId: "242582220963",
  appId: "1:242582220963:web:35da58513c05e400ed0f16",
  measurementId: "G-2JTQBNR8ZS"
}
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)



Vue.config.productionTip = false
Vue.prototype.$global = global
Vue.prototype.$api = global.api
Vue.prototype.$eventrack = (msg, method, map = {}) => {
	let params = {
		time: new Date(),
		message: msg,
		method: method,
		...map,
	}
	console.log(msg);
	// logEvent(analytics, msg, params)
}

new Vue({
  render: h => h(App),
}).$mount('#app')
